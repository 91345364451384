/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import device from '../../utils/mq'

const Header = styled.div`
  max-width: 1300px;
  padding: 0 50px;
  margin: 0 auto;
  margin-bottom: 40px;

  @media ${device.tablet} {
    padding: 0 100px;
  }
`

const HeroTitle = styled.h1`
  margin: 0 0 12px;
  font-size: 50px;
  letter-spacing: -3px;
  color: ${({ theme }) => theme.colors.brandPrimary};

  @media ${device.tablet} {
    font-size: 70px;
  }
`

const HeroSubline = styled.p`
  font-size: 18px;
  line-height: 1.45;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.brandPrimary};

  @media ${device.tablet} {
    font-size: 22px;
  }
`

const Hero = ({ heroData }) => (
  <section>
    <Header>
      <HeroTitle>{heroData.headline}</HeroTitle>
      <HeroSubline>{heroData.subline}</HeroSubline>
    </Header>
  </section>
)

export default Hero

export const fragment = graphql`
  fragment Hero on heroData {
    headline
    subline
  }
`
