/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/sections/Hero'
import TeamMembers from '../components/sections/TeamMembers'
import Partners from '../components/sections/Partners'
import SEO from '../components/Seo'

const About = ({ data: { allContentfulAboutPage } }) => {
  const { sections } = allContentfulAboutPage.nodes[0]
  return (
    <>
      <SEO
        title="Innential - About Us"
        description="Innential helps your teams perform and grow through hyper personalised learning."
      />
      {sections.map((section) => {
        const { typeName } = section

        switch (typeName) {
          case 'About Us':
            return <Hero key={typeName} {...section} />

          case '_Members':
            return <TeamMembers key={typeName} {...section} />

          default:
            return null
        }
      })}
    </>
  )
}

export default About

export const query = graphql`
  query {
    allContentfulAboutPage(filter: { name: { eq: "About Page" } }) {
      nodes {
        name
        sections {
          ... on ContentfulSectionHero {
            id
            typeName
            heroData: data {
              ... on ContentfulHero {
                headline
                subline
                callToAction {
                  title
                  link
                }
              }
            }
          }
          ... on ContentfulSectionMembers {
            id
            typeName
            membersData: data {
              members {
                name
                role
                image {
                  file {
                    url
                  }
                  gatsbyImageData(width: 120, quality: 90, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
