/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import device from '../../utils/mq'

const StyledSection = styled.section`
  padding: 45px 0 78px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.brandSecondary};
  text-align: center;
`
const Wrapper = styled.div`
  max-width: 1300px;
  padding: 0 50px;
  margin: 0 auto;
  margin-bottom: 100px;

  @media ${device.tablet} {
    padding: 0 100px;
  }
`

const Subtitle = styled.h2`
  font-size: 20px;
  margin: 0 auto;

  @media ${device.tablet} {
    font-size: 26px;
  }
`
const TeamContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  flex-wrap: wrap;
  padding-top: 25px;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;

  @media ${device.tablet} {
    justify-content: space-around;
  }
`
const SingleMember = styled.div`
  min-width: 200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  align-items: center;
  img {
    width: 100%;
    max-width: 160px;
    padding-bottom: 10px;
  }
  span {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

const TeamMembers = ({ membersData }) => (
  <>
    <StyledSection>
      <Wrapper>
        <Subtitle>Team</Subtitle>
        <TeamContainer>
          {/* <pre>{JSON.stringify(membersData, null, 2)}</pre> */}
          {membersData.members.map((member) => (
            <SingleMember key={member.name}>
              <GatsbyImage
                image={member?.image?.gatsbyImageData}
                alt={member.name}
              />
              <span>
                <b>{member.name}</b>
              </span>
              <span>{member.role}</span>
            </SingleMember>
          ))}
        </TeamContainer>
      </Wrapper>
    </StyledSection>
  </>
)

export default TeamMembers
